export const ROLES = {
  PARTNER_MANAGER: "AWS Partner Manager",
  FUNDING_APPROVER: "AWS Funding Approver",
  FUNDING_OPS: "AWS Global Funding Ops",
  FUNDING_ADMIN: "AWS System Admin",
  TECH_AUDITOR: "Tech Auditor",
  CASH_CLAIM_AUDITOR: "Cash Claim Auditor",
  SELF_SERVICE_USER: "SelfServiceUser",
  PARTNER_CONSULTANT: "AWS Partner Consultant",
  PARTNER_DEVELOPMENT_MANAGER: "AWS Partner Development Manager",
  SCALE_VIEW_ONLY_USER: "AWS Scale Viewer"
};
